// Grid

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  // xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  // xxl: 1320px
);

// TODO: Convert to rem
$grid-gutter-width: 40px;
$spacer: 1rem;
$spacers-custom: (
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 8,
  9: $spacer * 10,
  'y-large': $spacer * 5.875
);

// Colors
$brand-white: #FFFFFF;
$brand-light-gray: #E5E4E4;
$brand-sea-green: #9EEFE1;
$brand-light-purple: #A5A3FF;
$brand-blue: #4F2EFF;
$brand-gray: #8E9BAC;
$brand-dark-blue: #383846;

$brand-colors: (
  "brand-white": $brand-white,
  "brand-light-gray": $brand-light-gray,
  "brand-sea-green": $brand-sea-green,
  "brand-light-purple": $brand-light-purple,
  "brand-blue": $brand-blue,
  "brand-gray": $brand-gray,
  "brand-dark-blue": $brand-dark-blue
);

// Typography
$font-family-sans-serif: 'GT-Walsheim-Regular',
  system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  "Noto Sans",
  "Liberation Sans",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";

$body-text-align: null;
$body-color: $brand-dark-blue;
$body-bg: $brand-white;

$link-color: $brand-dark-blue;
$link-decoration: underline;
$link-shade-percentage: 90%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: null;

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: 34px;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom: $spacer * .5;
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: bold;
$headings-line-height: 1.1;
$headings-color: inherit;

$display-font-sizes: (
  1: 60px,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);

$display-font-family: $font-family-sans-serif;
$display-font-style: null;
$display-font-weight: lighter;
$display-line-height: $headings-line-height;

$font-family-light: 'GT-Walsheim-Light';
$font-family-thin: 'GT-Walsheim-Thin';
$font-family-bold: 'GT-Walsheim-Bold';