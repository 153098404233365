@import "mixins/font-face";

// @include font-face("Rubik", "../fonts/Rubik/static/Rubik-Regular", null, null, ttf);
// @include font-face("Rubik", "../fonts/Rubik/static/Rubik-Bold", bold, null, ttf);
// @include font-face("Rubik", "../fonts/Rubik/static/Rubik-Italic", null, italic, ttf);
// @include font-face("Rubik", "../fonts/Rubik/static/Rubik-BoldItalic", bold, italic, ttf);

@include font-face("GT-Walsheim-Regular", "../fonts/GT-Walsheim/static/GT-Walsheim-Regular", null, null, ttf);
@include font-face("GT-Walsheim-Bold", "../fonts/GT-Walsheim/static/GT-Walsheim-Bold", bold, null, ttf);
@include font-face("GT-Walsheim-Light", "../fonts/GT-Walsheim/static/GT-Walsheim-Light", null, null, ttf);
@include font-face("GT-Walsheim-Thin", "../fonts/GT-Walsheim/static/GT-Walsheim-Thin", null, null, ttf);
