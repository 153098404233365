@import "./fonts";
@import "./bootstrap";

// Global
body {
  font-size: 20px;
}

.font-weight-light {
  font-weight: lighter !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-family-thin {
  font-family: $font-family-thin !important;
}

.font-family-light {
  font-family: $font-family-light !important;
}

.font-family-bold {
  font-family: $font-family-bold !important;
}

a {
  &:hover {
    color: $brand-blue;
  }
}

a.link-phone {
  text-decoration: none;
}

a.break-word {
  word-break: break-word;
}

small,
.small {
  font-size: 14px;
}

// Buttons
.btn--icon {
  position: relative;

  &--download {
    &::after {
      content: '';
      background-image: url(../images/icons/icon-download-white.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 15px;
      height: 18px;
      position: absolute;
      top: 50%;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

}


// Nav
nav {
  .container {
    max-width: 1650px;
  }
}

@media screen and (max-width: 767px) {
  nav {
    .container {
      text-align: center;
    }
  }
}

// hero-and-section-2--wrapper
.hero-and-section-2--wrapper {
  position: relative;

  .wave-right {
    bottom: 34%;
  }
}

.wave {
  position: absolute;
  z-index: -1;

  &-left {
    left: 0;
  }

  &-right {
    right: 0;
    max-height: 250px;
  }

  &.wave-zIndex-0 {
    z-index: unset;
  }
}


// Hero
#hero {
  h1 {
    font-size: 3.5rem;

    strong {
      color: $brand-blue;
    }
  }
}

@media screen and (max-width: 1199px) {
  #hero {
    h1 {
      font-size: 2.8rem;
    }
  }
}

@media screen and (max-width: 575px) {
  #hero {
    h1 {
      font-size: 2.3rem;

      br {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  #hero {
    h1 {
      font-size: 2.1rem;
    }
  }
}

// section--2
.section--2 {
  position: relative;

  .container {
    h2 {
      max-width: 700px;

      strong {
        color: $brand-light-purple;
      }

      sup {
        font-size: .5em;
        top: -1em
      }
    }
  }

  &::after {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: $brand-light-gray;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) {
  .section--2 {
    .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

@media screen and (max-width: 575px) {
  .section--2 {
    .container {
      h4 {
        font-size: 1.2rem;
      }
    }
  }
}

// section--3
.section--3 {
  .container {
    h2 {
      max-width: 700px;
    }

    .box-shadow {
      box-shadow: 4px 2px 10px #d0d0d0;
    }
  }
}

// section--4
.flag {
  width: 70px;
  margin: auto;
  box-shadow: 4px 2px 10px #d0d0d0;

  &+.btn--icon--download {
    width: 100%;
    max-width: 230px;
    padding-left: 2em;
    padding-right: 2em;
    font-size: .8rem;

    &:hover {
      background-color: $brand-white !important;
      color: $brand-blue !important;

      &::after {
        background-image: url(../images/icons/icon-download-blue.svg);
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .section--4 {
    .btn--icon--download {
      &::after {
        width: 15px;
        height: 15px;
        right: 7px;
      }
    }
  }
}

// Section--5
.section--5 {
  .col-content {
    max-width: 97%;
  }
}

// Section--6
.section--6 {
  .col-content {
    max-width: 97%;
  }
}


// section--7
.quotation-box {
  position: relative;
  border: 3px solid $brand-sea-green;
  border-top-left-radius: 50px;

  &::after {
    content: '';
    background-color: #fff;
    background-image: url(../images/icons/icon-quotation-mark.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: calc(100% - 8px);
    width: 85px;
    height: 75px;
    position: absolute;
    top: -10px;
    left: -7px;
  }

  &__content {}

  .box-gray {
    position: absolute;
    bottom: -85px;
    right: 50px;
    max-width: 550px;

    h4 {
      letter-spacing: 1px;
    }
  }
}

@media screen and (max-width: 767px) {
  .quotation-box {
    padding: 5em 2em !important;

    &::after {}

    .box-gray {
      max-width: 90%;
      width: 90%;
      bottom: -85px;
      right: 50%;
      transform: translateX(50%);
    }
  }
}

@media screen and (max-width: 575px) {
  .quotation-box {
    padding-bottom: 6em !important;

    .box-gray {
      bottom: -120px;
    }
  }
}

@media screen and (max-width: 375px) {
  .quotation-box {
    padding-bottom: 7em !important;
  }
}

// Footer
// section--contact
.section--contact {
  position: relative;

  .container {
    hr {
      margin-top: 0;
      border-top: 2px solid $brand-white;
      opacity: 1;
    }
  }

  .wave-right {
    top: 50%;
    transform: translateY(-35%);
  }
}

.back-to-top {
  cursor: pointer;

  img {
    width: 15px;
    margin-left: 5px;
  }
}

// section--reference
.section--reference {
  position: relative;

  hr {
    border-top: 2px solid $brand-dark-blue;
  }

  ol {
    margin-left: 20px;
    width: calc(100% - 20px);

    li {
      line-height: 1.6;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

//footer
footer {
  .icon--CE-mark {
    width: 35px;
    margin-right: 15px;

    &+small {
      width: calc(100% - 65px);
    }
  }

  p {
    line-height: 20px;
  }
}



// SUB-PAGE
hr.hr--dark-blue {
  border-top: 2px solid $brand-dark-blue;
}

ol.custom-list {
  counter-reset: item;

  li {
    position: relative;
  }

  li:before {
    content: counters(item, ".") " ";
    counter-increment: item 1;
    position: absolute;
  }

  li.start--2:before {
    counter-increment: item 2;
  }
}

// table
table {
  border-collapse: unset;

  td,
  th {
    padding: 1em;
  }

  &.table--mobile {
    display: none;
  }

  &.table--desktop {
    td {
      vertical-align: top;
      background-color: $brand-light-gray;
    }

    th {
      background-color: $brand-dark-blue;
      color: #fff;
    }

    td,
    th {
      &:nth-child(1) {
        width: 30%;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 50%;
      }
    }

    &.table--5cols{
      td,
      th {
        &:nth-child(1) {
          width: 20%;
        }

        &:nth-child(2) {
          width: 10%;
        }

        &:nth-child(3) {
          width: 50%;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {

  // table
  table {

    td,
    th {
      font-size: 20px;
      line-height: 30px;
    }
  }
}


@media screen and (max-width: 991px) {
  table {
    &.table--5cols{
      &.table--desktop {
        display: none;
      }
  
      &.table--mobile {
        display: table;
  
        td {
          vertical-align: top;
  
          &:nth-child(1) {
            width: 30%;
            background-color: $brand-dark-blue;
            color: #fff;
          }
  
          &:nth-child(2) {
            background-color: $brand-light-gray;
            width: 70%;
          }
        }
  
      }
    }
  }
}

@media screen and (max-width: 767px) {
  table {
    &.table--desktop {
      display: none;
    }

    &.table--mobile {
      display: table;

      td {
        vertical-align: top;

        &:nth-child(1) {
          width: 30%;
          background-color: $brand-dark-blue;
          color: #fff;
        }

        &:nth-child(2) {
          background-color: $brand-light-gray;
          width: 70%;
        }
      }

    }
  }
}

@media screen and (max-width: 575px) {
  table {
    &.table--mobile {
      td {
        padding: 10px;
        font-size: 14px;

        &:nth-child(1) {
          width: 100px;
        }

        &:nth-child(2) {
          width: 100%;
        }
      }

    }
  }
}

@media screen and (max-width: 480px) {
  table {
    &.table--mobile {
      td {
        font-size: 13px;
      }
    }
  }
}

.subpage--body {
  a {
    font-family: $font-family-bold;
  }
}

@media screen and (min-width: 1200px) {
  .subpage--body {
    h3 {
      font-size: 29px;
      line-height: 38px;
    }

    p {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

// HERO
.subpage--hero {
  position: relative;

  p {
    font-size: 17px;
    line-height: 20px;
  }

  .wave {
    &-left {
      left: 0;
      top: 0;
    }

    &-right {
      right: 0;
      bottom: 0;
    }
  }
}

@media screen and (min-width: 1200px) {
  .subpage--hero {
    h1.display-4 {
      font-size: 59px;
      line-height: 68px;
    }
  }
}

@media screen and (max-width: 991px) {
  .subpage--hero {
    .wave {
      &-right {
        max-height: 220px;
        bottom: -20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .subpage--hero {
    .wave {
      &-right {
        max-height: 150px;
        bottom: -40px;
      }
    }
  }
}


@media screen and (max-width: 575px) {
  .subpage--hero {
    .wave {
      &-right {
        max-height: 120px;
      }
    }

    p {
      font-size: 16px;
    }
  }
}
